import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Week = ({user, weekNumber, setWeekNumber}) => {
    const navigate = useNavigate();

    const renderState = (state) => {
        if (state == "pending") {
          return <div className="state pending">En attente</div>
        } else if (state == "booked") {
          return <div className="state booked">Plannifié</div>
        } else if (state == "done") {
          return <div className="state done">Réalisé</div>
        } else if (state == "canceled") {
          return <div className="state canceled">Annulé</div>
        }
      }
   
    return (
        <div className="week">
            <div className="week-header">
                <h3>{user?.month?.month_title} {user?.month?.year}</h3>
                <div className="cal-nav">
                    <FontAwesomeIcon onClick={() => {setWeekNumber(weekNumber - 1)}} className="cal-nav-left" icon={faChevronLeft} color="#2f3649"/>
                    <FontAwesomeIcon onClick={() => {setWeekNumber(weekNumber + 1)}} className="cal-nav-right" icon={faChevronRight} color="#2f3649"/>
                </div>
            </div>
            <div className="calendar">
                <div className="days">
                    <div>Lun</div>
                    <div>Mar</div>
                    <div>Mer</div>
                    <div>Jeu</div>
                    <div>Ven</div>
                    <div>Sam</div>
                    <div>Dim</div>
                </div>
                <div className="content">
                    {
                        user?.month?.month?.map((d, idx) => {
                            return (
                                <div key={idx} className={`day ${d.today ? "active" : ""} ${d.booked_events < 0 ? "other-month" : ""}`}>
                                    {/* <div className="name">{d.day}</div> */}
                                    <div className="number">{d.date}</div>
                                    <div className={`point ${d.booked_events > 0 ? 'visible' : ''}`}>.</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className="event-list">
                {
                    user?.booked_assignations?.map((e, idx) => {
                        return (
                            <div key={idx} className={`${e.state} card event-card`}>
                                <div className="header">
                                    <div className="localisation">{e.city} {e.localisation}</div>
                                    <div className="date">{e.event_date}</div>
                                </div>
                                <div className="name">{e.name}</div>
                                {renderState(e.state)}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )

};

export default Week;
