import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from './navbar'
import Map from './map'
import DataTable from './datatable';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileAlt} from "@fortawesome/free-solid-svg-icons";

const Event = () => {

  const navigate = useNavigate();
  const [event, setEvent] = useState({expenses: [], documents: []})
  const [user, setUser] = useState({});

  useEffect(() => {
        const current_url = new URL(window.location.href)
        const id = current_url.pathname.split("/").pop()
        const url = `/api/v1/events/${id}`;
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content
        fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        }).then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error("Network response was not ok.");
          })
          .then((res) => {
            setEvent(res)
            setUser(res.technician)
          })
          .catch(() => {
            navigate("/")
          });
    }, []);

    const tradType = (doc_type) => {
      if (doc_type == "vital") {
        return "Carte vitale"
      } else if (doc_type == "rib") {
        return "RIB"
      } else if (doc_type == "id") {
        return "Carte d'identité"
      } else if (doc_type == "passport") {
        return "Passeport"
      } else if (doc_type == "driving_licence") {
        return "Permis de conduire"
      } else if (doc_type == "degree") {
        return "Diplôme F4T2 niveau 1 ou 2"
      } else if (doc_type == "agreement") {
        return "Agrément préfectoral"
      } else if (doc_type == "caces") {
        return "CACES"
      }
    }

    const renderState = (state) => {
      if (state == "pending") {
        return <div className="state pending">En attente</div>
      } else if (state == "booked") {
        return <div className="state booked">Plannifié</div>
      } else if (state == "done") {
        return <div className="state done">Réalisé</div>
      } else if (state == "canceled") {
        return <div className="state canceled">Refusé</div>
      }
    }

    const documentColumns = [
      {Header: "", accessor: "id",  disableFilters: true, show: false},
      {Header: "Nom", accessor: "name",  disableFilters: true, show: true},
      {Header: "Type", accessor: "doc_type",  disableFilters: true, show: true, 
        Cell: ({ row }) => tradType(row.values.doc_type)
      }
    ]

    const expenseColumns = [
      {Header: "", accessor: "id",  disableFilters: true, show: false},
      {Header: "Evènement", accessor: "event_name",  disableFilters: true, show: true},
      {Header: "Commentaire", accessor: "comment",  disableFilters: true, show: true},
      {Header: "Statut", accessor: "state",  disableFilters: true, show: true},
      {Header: "Fichier attaché", accessor: "file_url",  disableFilters: true, show: true,
        Cell: ({ row }) => {
          return  row.values.file_url ? <a href={row.values.file_url} target="_blank"><FontAwesomeIcon color="#AFAFAF" icon={faFileAlt} size={"2x"} className="icon-details" style={{minWidth: "50px"}}/></a> : ''
        }
      },
    ]

    const noDocument = (
      <div className="nocontent small">
        <h4>
          Pas de documents remontés
        </h4>
      </div>
    );

    const noExpense = (
      <div className="nocontent small">
        <h4>
          Pas de notes de frais remontées
        </h4>
      </div>
    );

    const listExpenses = <DataTable columns={expenseColumns} data={event.expenses} search={false} s />
    const listDocuments = <DataTable columns={documentColumns} data={event.documents} search={false} s />

    
   
    return (
        <div className="main">
            <Navbar />
            <div className="events work-space">
                <div className="header">
                </div>
                <div className="body">
                    <h2>{event.name} | {event.client_name} | {event.event_date}</h2>
                    {renderState(event.state)}
                    <div className="event-content">
                      <div className="left">
                        <div className="bloc">
                          <h3>Documents</h3>
                          {event.documents?.length > 0 ? listDocuments : noDocument}
                        </div>
                        <div className="bloc">
                          <h3>Notes de frais</h3>
                          {event.expenses?.length > 0 ? listExpenses : noExpense}
                        </div>
                      </div>
                      <div className="right">
                        <div className="bloc">
                          <h3>{event.localisation} {event.city}</h3>
                          <Map address={`${event.localisation} ${event.city}`} />
                        </div>
                      </div>  
                    </div>  
                </div>
            </div>
        </div>
    )

};

export default Event;
