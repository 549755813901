import React, { useEffect, useState } from "react";
import Dropzone from 'react-dropzone-uploader'

const ExpenseFilezone = ({setRefresh, close, expense}) => {

  const getUploadParams = ({ file, meta }) => {
    const body = new FormData()
    body.append('file', file)
    //body.append('importId', meta.id)
    return { method: 'put', url: `/api/v1/expenses/${expense.id}`, body }
 }

  const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }

  const handleSubmit = (files, allFiles) => {
    console.log(files.map(f => f.meta))
    allFiles.forEach(f => f.remove())
    setRefresh(true)
    close()
  }

    return (
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        canRestart={false}
        inputContent={`${expense?.event} - Ajouter un document`}
        inputWithFilesContent="Ajouter un document"
        onSubmit={handleSubmit}
      />
    )

};

export default ExpenseFilezone;

