import React from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useRowSelect
} from "react-table";
import { matchSorter } from 'match-sorter'

// Create an editable cell renderer
const EditableCell = ({
  cell: { value: initialValue },
  row: { index },
  column: { id, linkContent, show },
  updateMyData, // This is a custom function that we supplied to our table instance
  updateCallback,
  editable,
  clickable,
}) => {
  // We need to keep and update the state of the cell normally
  initialValue = (initialValue ===  undefined || initialValue ===  null) ? '' : initialValue
  const [value, setValue] = React.useState(initialValue);

  const onChange = e => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value, updateCallback);
  };

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (clickable) {
    return <a href={initialValue}>{linkContent}</a>
  }
  if (!editable) {
    return `${initialValue}`;
  }

  return <input value={value} onChange={onChange} onBlur={onBlur} />;
};

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select value={filterValue} onChange={e => setFilter(e.target.value || undefined) }>
      <option value="">All</option>
      {options.map((option, i) => ( <option key={i} value={option}> {option} </option> ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </div>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id }
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex"
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1]
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem"
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem"
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

// Be sure to pass our updateMyData and the disablePageResetOnDataChange option
function Table({ columns, data, updateMyData, disablePageResetOnDataChange, notifs, updateCallback, showColumns, search }) {
  // const headerWithMenus = columns.filter((column) => column.menu);
  const headerWithMenus = columns.reduce(function(filteredArr, column) {
    if (column.menu) {
      const m = column.menu.map(el => {
        const h = {accessor: column.accessor};
        return {...el, ...h};
      });
      filteredArr.push(m);
    }
    return filteredArr;
  }, []);

  const firstMenu = headerWithMenus[0] === undefined ? null : headerWithMenus[0][0]

  // const [tableState, setTableState] = useTableState({
  //   pageIndex: 0,
  //   selectedMenu: firstMenu,
  //   crossColumnsSearch: "" // NOT USED YET
  // });

  const [selectedMenu, setSelectedMenu] = React.useState(firstMenu);

  const shouldShow = (column) => {
    if(showColumns && selectedMenu) {
      const cols = showColumns[selectedMenu.val] || [];
      return cols.includes(column.accessor);
    }
    else {
      return column.show === undefined ? true : column.show;
    }
  }
  columns = React.useMemo(() => {
    columns.map(col =>{
      const fs = flatHeaders;
      col.show = shouldShow(col)
    })
    return columns;
  }, [selectedMenu, columns]);

  //{ keys: [row => row.values[id]] }
  data = React.useMemo(() => {
    if (selectedMenu) {
      return data.filter(row => {
        return selectedMenu.val === row[selectedMenu.accessor] || selectedMenu.val.includes(row[selectedMenu.accessor])
      })
    }
    return data;
  }, [selectedMenu, data]);

  const [crossColumnsSearch, setSearch] = React.useState("");
  data = React.useMemo(() => {
    if (crossColumnsSearch) {
      const dataKeys = data.length > 0 ? Object.keys(data[0]) : null
      return matchSorter(data, crossColumnsSearch, { keys: dataKeys })
    }
    return data;
  }, [crossColumnsSearch, data]);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => {
      ({
          // Let's set up our default Filter UI
          Filter: DefaultColumnFilter,
          // And also our default editable cell
          Cell: EditableCell
        })},
    []
  );

  // Use the state and functions returned from useTable to build your UI
  // const tableState = useTableState({ pageIndex: 1, selectedMenu: selectedMenu});

  // Render the UI for your table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    flatHeaders,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 25 },
    },
    useSortBy,
    usePagination
  )

  const handleSearchChange = e => setSearch(e.target.value); // TO DO: try to leverage tableState
  const handleMenuClick = e => {
    setSelectedMenu({
      accessor: e.target.getAttribute('accessor'),
      val: e.target.getAttribute('val'),
      display: e.target.getAttribute('display'),
      // columns: e.target.getAttribute('columns').split(',')
    })
  };

  const renderSearch = (search) => {
    if (search) {
      return (
         <div className="datatable__menu__search">
          <input className="datatable__menu__search__input" value={crossColumnsSearch} onChange={handleSearchChange} placeholder='Rechercher...' />
          <div className="datatable__menu__search__icon">
            <div className="img"></div>
          </div>
        </div>
        )
      }
  }

  const renderPagination = (pageCount) => {
    if (pageCount > 1) {
    return (
      <div className='datatable__pagination-wrapper'>
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
          </button>{" "}
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize} par page
              </option>
            ))}
          </select>
        </div>
      </div>
    )}
  }

  const selectedMenuVal = selectedMenu && selectedMenu.val ? selectedMenu.val : null

  const isMenuClass = headerWithMenus[0] ? "datatable__menu with-menu" : "datatable__menu search-only"

  if(data.length === 0 ) {
    return (
      <div className='block'>
        <div className='datatable__no-data datatable__table-wrapper'>
          <table {...getTableProps()} >
            <thead>
              <tr>
                {flatHeaders.filter(header => header.show).map((header, idx) => (
                  <th {...header.getHeaderProps()} className={`${selectedMenuVal} ${header.id}`}>
                    <span {...header.getSortByToggleProps()}>
                      {header.render("Header")}
                      {/* Add a sort direction indicator */}
                      {header.isSorted ? (header.isSortedDesc ? <span className='caret'></span> : <span className='caret caret--reversed'></span>) : ""}
                    </span>
                  </th>
                ))}
            </tr>
            </thead>
            <tbody id="elementList">
              <tr>
                <td colSpan="3" style={{"width": '800px'}}>
                  Pas de résultats
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    )
  }
  return (
    <div className='block'>
      <div className='datatable__table-wrapper'>
        <table {...getTableProps()} className="">
          <thead>
            <tr>
              {flatHeaders.filter(header => header.show).map((header, idx) => (
                <th {...header.getHeaderProps()} className={`${selectedMenuVal} ${header.id}`}>
                  <span {...header.getSortByToggleProps()}>
                    {header.render("Header")}
                    {/* Add a sort direction indicator */}
                    {header.isSorted ? (header.isSortedDesc ? <span className='caret'></span> : <span className='caret caret--reversed'></span>) : ""}
                  </span>
                  {/* Render the columns filter UI */}
                  <div>{header.canFilter ? header.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody id="elementList">
            {page.map(
              row => {
                prepareRow(row)
                const classes = row.original.state
                return (
                  <tr {...row.getRowProps()} className={classes}>
                    {row.cells.map(cell => {
                      if (cell.column.show !== false){
                        return (
                          <td {...cell.getCellProps()} className={`${selectedMenuVal} ${cell.column.id}`}>
                            {cell.render("Cell", { editable: !!cell.column.editable, clickable: !!cell.column.clickable })}
                          </td>
                        )
                      }
                    })}
                  </tr>
                )
              }
            )}
          </tbody>
        </table>
      </div>
      {renderPagination(pageCount)}
    </div>
  )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== "number";

// This is a custom aggregator that
// takes in an array of values and
// returns the rounded median
function roundedMedian(values) {
  let min = values[0] || "";
  let max = values[0] || "";

  values.forEach(value => {
    min = Math.min(min, value);
    max = Math.max(max, value);
  });

  return Math.round((min + max) / 2);
}

function formatSelectableColumn(columns) {
  return columns.map(column => {
    if(column.selectable) {
      column.Header = ({ getToggleAllRowsSelectedProps }) => {
        return (
          <div>
            <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
          </div>
        )
      }

      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      column.Cell = ({ row }) => {
        return (
          <div>
           <input type="checkbox" defaultValue={row.cells[0].value} name={row.cells[0].column.id}
              {...row.getToggleRowSelectedProps()}
            />
          </div>
        )
      }
    }
    return column
  })
}

function DataTable({columns, data, selectRow, notifs, updateCallback, showColumns, search}) {
  notifs = React.useMemo(() => notifs) // TO DO: why memo not state ?
  columns = formatSelectableColumn(columns);
  columns = React.useMemo( () => columns, columns);
  showColumns = React.useMemo( () => showColumns);
  search = React.useMemo(() => search);
  // [data, setData] = React.useState(() => data);
  const reactDataStateArray = React.useState(() => data); // not ideal but data is already a const and I still want to use data then after
  const setData = reactDataStateArray[1];
  data = reactDataStateArray[0].lenght > 0 ? reactDataStateArray[0] : data;
  const [originalData] = React.useState(data);
  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  const [skipPageReset, setSkipPageReset] = React.useState(false)
  updateCallback = React.useMemo(() => updateCallback);
  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnID and new value to update the
  // original data
  const updateMyData = (rowIndex, columnID, value, updateCallback) => {
      setSkipPageReset(true)
      setData(old =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnID]: value,
            }
          }
          return row
        })
      )
      if(updateCallback) updateCallback(value);
    }


  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => { setSkipPageReset(false)}, [data])

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  // TO DO: change to remove filters
  const resetData = () => setData(originalData)
  return (
    <Table
      columns={columns}
      showColumns={showColumns}
      data={data}
      updateMyData={updateMyData}
      updateCallback={updateCallback}
      disablePageResetOnDataChange={skipPageReset}
      notifs={notifs}
      search={search}
    />
  );
}

export default DataTable;