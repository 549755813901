import React, { useEffect } from "react";
import { PatternFormat } from 'react-number-format';

const NumberInput = ({editable, object, setObject, slug, title, setRefresh, pattern}) => {
    
    const handleChange = () => {
        setObject( { ...object, [slug]: event.target.value })
    }

    const handleBlur = () => {
        setRefresh(`${slug}-${event.target?.value}`)
    }
    
    if (pattern == "iban") {
    return (  
        <div className="form-bloc double">
            <label htmlFor={slug} id={slug} className="s_label">{title}</label>
            <PatternFormat type="text" displayType="text" className="list-input" format="## ## #### #### #### #### #### ###" allowEmptyFormatting value={object[slug]} onChange={handleChange} onBlur={handleBlur}  />
        </div>
    ) } else if (pattern == "bic") {
        return (  
            <div className="form-bloc">
                <label htmlFor={slug} id={slug} className="s_label">{title}</label>
                <PatternFormat type="text" className="list-input" format="#### ## ## ###" allowEmptyFormatting value={object[slug]} onChange={handleChange} onBlur={handleBlur} />
            </div>
    ) } else if (pattern == "phone") {
        return (  
            <div className="form-bloc">
                <label htmlFor={slug} id={slug} className="s_label">{title}</label>
                <PatternFormat type="text" className="list-input" format="## ## ## ## ## ##" allowEmptyFormatting value={object[slug]} onChange={handleChange} onBlur={handleBlur} />
            </div>
        ) }


    else if (pattern == "money") {
      return (
        <div className="form-bloc">
          <label htmlFor={slug} id={slug} className="s_label">{title}</label>
          <input type="number" step="0.1" value={object[slug]} onChange={handleChange} onBlur={handleBlur} />
        </div>
      ) }

    else {
        return (  
            <div className="form-bloc">
                <label htmlFor={slug} id={slug} className="s_label">{title}</label>
                <PatternFormat type="text" className="list-input" format="############" allowEmptyFormatting value={object[slug]} onChange={handleChange} onBlur={handleBlur} />
            </div>
        ) 
    }

};

export default NumberInput;
