import React, {useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThLarge, faCalendarCheck, faStarOfLife, faMoneyCheckAlt, faFolderOpen, faUserEdit, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState({});

    useEffect(() => {
        const url = `/api/v1/technicians?week_number=0`;
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-CSRF-Token": csrfToken
            },
            credentials: "same-origin",
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
            throw new Error("Network response was not ok.");
        })
          .then((res) => {
              setUser(res)
          })
          .catch(() => {
              navigate("/")
          });
    }, []);

    const logoutUser = () => {
        const url = "users/sign_out";
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content
        fetch(url, {
            method: 'DELETE',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-CSRF-Token": csrfToken
            },
            credentials: "same-origin",
          }).then(r => {
            location.reload();
          })
          .catch(error => console.log(error))
    }
   
    return (
        <div className="navbar vertical-navbar">

            <div className="navbar-bloc entete">
                <div className="logo"></div>
            </div>

            <div className="navigation">
                <Link className="nav-el" to={'/'}>
                    <div className="icon active">
                        <FontAwesomeIcon className="icon-details left" icon={faThLarge} color="#1ABC9C" inverse/>
                    </div>
                    Dashboard
                </Link>
                <Link className="nav-el" to={'/events'}>
                    <div className="icon">
                        <FontAwesomeIcon className="icon-details left" icon={faStarOfLife} color="#1ABC9C" inverse/>
                    </div>
                    Evènements
                </Link>
                <Link className="nav-el" to={'/schedule'}>
                    <div className="icon">
                        <FontAwesomeIcon className="icon-details left" icon={faCalendarCheck} color="#1ABC9C" inverse/>
                    </div>
                    Disponibilités
                </Link>
                <Link className="nav-el" to={'/expenses'}>
                    <div className="icon">
                        <FontAwesomeIcon className="icon-details left" icon={faMoneyCheckAlt} color="#1ABC9C" inverse/>
                    </div>
                    Notes de frais
                </Link>
                <Link className="nav-el" to={'/documents'}>
                    <div className="icon">
                        <FontAwesomeIcon className="icon-details left" icon={faFolderOpen} color="#1ABC9C" inverse/>
                    </div>
                    Mes Documents personnels
                </Link>
                <Link className="nav-el" to={'/shared_documents'}>
                    <div className="icon">
                        <FontAwesomeIcon className="icon-details left" icon={faFolderOpen} color="#1ABC9C" inverse/>
                    </div>
                    Documents communs SDF
                </Link>
                <Link className="nav-el" to={'/profile'}>
                    <div className="icon">
                        <FontAwesomeIcon className="icon-details left" icon={faUserEdit} color="#1ABC9C" inverse/>
                    </div>
                    Profil
                </Link>
            </div>

            <div>
                <div className="deco">
                    <a onClick={logoutUser}>
                        <div className="icon">
                            <FontAwesomeIcon className="icon-details left" icon={faArrowLeft} color="#C6C6C6"/>
                        </div>
                        Déconnexion
                    </a>
                </div>
            </div>
        </div>
    )

};

export default Navbar;
