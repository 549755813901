import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from './navbar'
import DataTable from './datatable';
import ExpenseModal from './expense_modal';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileAlt} from "@fortawesome/free-solid-svg-icons";

const Expenses = () => {

  const navigate = useNavigate();
  const [expenses, setExpenses] = useState([])
  const [refresh, setRefresh] = useState()

  useEffect(() => {
        const url = `/api/v1/expenses`;
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content
        fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        }).then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error("Network response was not ok.");
          })
          .then((res) => {
            setExpenses(res)
          })
          .catch(() => {
            navigate("/")
          });

    }, [refresh]);

    const columns = [
      {Header: "", accessor: "id",  disableFilters: true, show: false},
      {Header: "Catégorie", accessor: "category",  disableFilters: true, show: true},
      {Header: "Commentaire", accessor: "comment",  disableFilters: true, show: true},
      {Header: "Statut", accessor: "state",  disableFilters: true, show: true},
      {Header: "Nom de l’évènement", accessor: "event_name",  disableFilters: true, show: true},
      {Header: "Date de l’évènement", accessor: "event_date",  disableFilters: true, show: true},
      {Header: "Dénomination du client", accessor: "event_client_name",  disableFilters: true, show: true},
      {Header: "Fichier attaché", accessor: "file_url",  disableFilters: true, show: true,
        Cell: ({ row }) => {
          return  row.values.file_url ? <a href={row.values.file_url} target="_blank"><FontAwesomeIcon color="#AFAFAF" icon={faFileAlt} size={"2x"} className="icon-details" style={{minWidth: "50px"}}/></a> : ''
        }
      },
      // {Header: "Action", accessor: "",  disableFilters: true, show: true,
      //   Cell: ({ row }) => {
      //     return <Button onClick={() => handleDelete(row.values.id)}>Supprimer</Button>
      //   }
      // },
    ]

    const listExpenses = <DataTable columns={columns} data={expenses} search={false} s />

    const noExpense = (
      <div className="nocontent">
        <h4>
          Pas de note de frais remontée
        </h4>
      </div>
    );
   
    return (
        <div className="main">
            <Navbar />
            <div className="expenses work-space">
                <div className="header">
                </div>
                <div className="body">
                    <div className="page-title">
                      <h2>Notes de frais</h2>
                      <ExpenseModal setRefresh={setRefresh}/>
                    </div>
                    {expenses.length > 0 ? listExpenses : noExpense}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <p>Pour déposer une demande de remboursement, cliquez sur le bouton en haut à
                        droite de l’écran. Attention, pour chaque demande de remboursement il faut un justificatif et
                        vous devez obligatoirement renseigner les items (évènement, catégorie, montant) et joindre le
                        justificatif (pdf, photo).</p>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    )

};

export default Expenses;
