import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from './navbar'
import DataTable from './datatable';
import DocumentModal from './document_modal';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

const Documents = () => {

  const navigate = useNavigate();
  const [documents, setDocuments] = useState([])
  const [refresh, setRefresh] = useState()

  useEffect(() => {
        const url = `/api/v1/documents`;
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content
        fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        }).then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error("Network response was not ok.");
          })
          .then((res) => {
            setDocuments(res)
          })
          .catch(() => {
            navigate("/")
          });

    }, [refresh]);

    const tradType = (doc_type) => {
      if (doc_type == "vital") {
        return "Carte vitale"
      } else if (doc_type == "rib") {
        return "RIB"
      } else if (doc_type == "id") {
        return "Carte d'identité"
      } else if (doc_type == "passport") {
        return "Passeport"
      } else if (doc_type == "driving_licence") {
        return "Permis de conduire"
      } else if (doc_type == "degree") {
        return "Diplôme F4T2 niveau 1 ou 2"
      } else if (doc_type == "agreement") {
        return "Agrément préfectoral"
      } else if (doc_type == "caces") {
        return "CACES"
      } else {
        return doc_type
      }
    }

  const handleDelete = (id) => {
    const url = `/api/v1/documents/${id}`;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin",
    }).then((res) => {
      if (res.ok) {
        const newList = documents.filter((item) => item.id !== id);
        setDocuments(newList)
      }else {
        throw new Error("Network response was not ok.");
      }
    })

  }

    const columns = [
      {Header: "", accessor: "id",  disableFilters: true, show: false},
      {Header: "Type", accessor: "doc_type",  disableFilters: true, show: true,
        Cell: ({ row }) => {return <a href={row.values.sp_attachment_link} target="_blank">{tradType(row.values.doc_type)}</a>}
      },
      {Header: "Déposé le", accessor: "created_at",  disableFilters: true, show: true,
      },
      {Header: "", accessor: "sp_attachment_link",  disableFilters: true, show: false},
    ]

    const listDocuments = <DataTable columns={columns} data={documents} search={false} s />
    const toolTip =       <Tooltip>Pour toute modification de documents, merci de l'envoyer à admin@contact.soirsdefetes.com</Tooltip>

    const noDocument = (
      <div className="nocontent">
        <h4>
          Pas de documents remontés
        </h4>
      </div>
    );
   
    return (
        <div className="main">
            <Navbar />
            <div className="documents work-space">
                <div className="header">
                </div>
                <div className="body">
                    <div className="page-title">
                      <h2>Documents</h2>
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={toolTip}
                      >
                        <FontAwesomeIcon className="icon-details" icon={faQuestionCircle} color="#41A1D7" />
                      </OverlayTrigger>
                    </div>
                    {documents.length > 0 ? listDocuments : noDocument}
                </div>
            </div>
        </div>
    )

};

export default Documents;
