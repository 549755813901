import React, { useEffect } from "react";
import DatePicker from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { registerLocale } from  "react-datepicker";
registerLocale('fr', fr)

const DateInput = ({editable, object, setObject, slug, title, setRefresh}) => {

    const handleChange = date => {
        setObject( { ...object, [slug]: date })
        setRefresh(`${slug}-${date}`)
    }
    

    if (editable) {
    //let date = new Date(object[slug])
    return (  
        <div className="form-bloc">
            <label htmlFor={slug}>{title}</label>
            <DatePicker
                selected={object[slug]}
                onChange={handleChange}
                dateFormat="yyyy-MM-DD"
                locale="fr"
            />
        </div>
    ) } else {
        return (  
            <div className="form-bloc">
                <label htmlFor={slug} id={slug} className="s_label">{title}</label>
                <p>{object[slug]}</p>
            </div>
        ) 
    }

};

export default DateInput;


