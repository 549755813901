import React, { useEffect, useState } from "react";
import ExpenseFilezone from './expense_filezone'
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import SelectInput from "./form/select_input";
import TextInput  from "./form/text_input";
import NumberInput from "./form/number_input";
import FileInput from "./form/file_input";


const ExpenseModal = ({setRefresh, mode}) => {
    const [showModal, setShowModal] = useState(false)
    const [step, setStep]= useState(0)
    const [type, setType] = useState("")
    const [expense, setExpense] = useState({})
    const [events, setEvents] = useState([])
    const [options, setOptions] = useState([])

    useEffect(() => {
            const url = `/api/v1/events`;
            const csrfToken = document.querySelector('meta[name="csrf-token"]').content
            fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-CSRF-Token": csrfToken
            },
            credentials: "same-origin",
            }).then((res) => {
                if (res.ok) {
                return res.json();
                }
                throw new Error("Network response was not ok.");
            })
            .then((res) => {
                setEvents(res)
                updateOptions(res)
            })
            .catch((res) => {
                console.log(res)
            });
        }, []);

    const close = () =>{
        setShowModal(false);
      }
  
    const open = () => {
        setShowModal(true);
        setStep(0)
    }

    const onClick = (step) => {
      const url = `/api/v1/expenses`;
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content
      const form = new FormData()
      Object.entries(expense).forEach(([key, value]) => {
        form.append(`expense[${key}]`, value);
      })
      close()
      fetch(url, {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: form
      }).then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
        .then((res) => {
          setExpense(res)
        })
    }

    const updateOptions = (events) => {
        let values = []
        events.map( e => {
            let label = `${e.name} | ${e.event_date} | ${e.client_name}`
            values.push({label: label, value: e.id})
        })
        setOptions(values)
    }

    const renderContent = () => {

        let catOptions = [
           { value: "Carburant", label: "Carburant" },
           { value: "Péage", label: "Péage" },
           { value: "Restauration", label: "Restauration" },
           { value: "Hébergement", label: "Hébergement" },
           { value: "Divers", label: "Divers" },
        ]


        if (step == 0) {
            return (
                <>
                  <NumberInput editable={true} object={expense} setObject={setExpense} slug="amount" title="Montant en euros TTC" pattern={"money"} />
                  <TextInput editable={true} object={expense} setObject={setExpense} slug="comment" title="Commentaire" />
                  <SelectInput editable={true} object={expense} setObject={setExpense} slug="category" title="Catégorie" options={catOptions} />
                  <SelectInput editable={true} object={expense} setObject={setExpense} slug="assignation" title="Evènement" options={options} />
                  <FileInput editable={true} object={expense} setObject={setExpense} slug="file" title="Document" />
                  <Button onClick={() => onClick(1)}>Valider</Button>
                </>
            )
        } else {
            return <ExpenseFilezone type={type} expense={expense} setRefresh={setRefresh} close={close} />
        } 
    }

    const renderBtn = (mode) => {
      if (mode == "dashboard") {
        return (
          <OverlayTrigger trigger={["hover"]}
              overlay={
                <Tooltip>
                  Nouvelle note de frais
                </Tooltip>
              }
            > 
            <Button className="shortcut" onClick={open}>
              <FontAwesomeIcon icon={faMoneyCheckAlt} color={"#FFF"} size="lg" pull="left" />
            </Button>
          </OverlayTrigger>
        )
      } else {
        return (
          <Button onClick={open}>
              + note de frais
          </ Button>
        )
      }
    }

    return (
        <>
        {renderBtn(mode)}

        <Modal id="expensesModal" className="expenses-modal" show={showModal} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>
          <h2 className="modal-title">
            Nouvelle note de frais
          </h2>
          </Modal.Title>
        </Modal.Header>
          <Modal.Body>
            { renderContent() }
          </Modal.Body>
        </Modal>
        </>
    )

};

export default ExpenseModal;

