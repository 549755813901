import React, { useEffect } from "react";
import Select from 'react-select'

const SelectInput = ({editable, object, setObject, slug, title, options=[], setRefresh}) => {
    const defaultVal = options.find(obj => obj.value === object[slug]);
    // console.log(object)
    // console.log(slug)
    // console.log(defaultVal)

    const onSelectChange = value => {
        setObject( { ...object, [slug]: value?.value })
        setRefresh(`${slug}-${value?.value}`)
    }

    if (editable) {
        return (
        <div className="form-bloc select-input">
            <label htmlFor={slug} id={slug} className="s_label">{title}</label>
            <Select className="test" classNamePrefix="react-select" value={defaultVal} options={options} onChange={onSelectChange} />
        </div>
        
        )
    } else {
        return (
        <div className="form-bloc inactive-input">
            <label htmlFor={slug} id={slug} className="s_label">{title}</label>
            {object[slug]}
        </div>
        )
    }

}


export default SelectInput;