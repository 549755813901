import React from 'react';

 const Map = ({address}) => {
    return (
        <div className="google-map-code">
            <iframe
                width="450"
                height="250"
                frameborder="0" style={{border:0}}
                referrerpolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyD1yxH36RDIT0Y_GUrx4P0R_c_MvgUU_eU&q=${address}`}
                allowfullscreen>
            </iframe>
        </div>
    );
 }

 export default Map;