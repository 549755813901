import React from "react";
import { BrowserRouter as Router, Route, Redirect, Routes } from 'react-router-dom';
import { createBrowserHistory as history } from 'history';
import Dashboard from "../components/dashboard";
import Events from "../components/events";
import Event from "../components/event";
import Documents from "../components/documents";
import SharedDocuments from "../components/shared_documents";
import Schedule from "../components/schedule";
import Profile from "../components/profile";
import Expenses from "../components/expenses";

export default (
  <Router history={history}>
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route exact path="/events" element={<Events />} />
      <Route path="/events/:id" element={<Event />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/documents" element={<Documents />} />
      <Route path="/schedule" element={<Schedule />} />
      <Route path="/expenses" element={<Expenses />} />
      <Route path="/shared_documents" element={<SharedDocuments />} />
    </Routes>
  </Router>
);