import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from './navbar'
import DataTable from './datatable';

const Events = () => {

  const navigate = useNavigate();
  const [events, setEvents] = useState([])

  useEffect(() => {
        const url = `/api/v1/events`;
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content
        fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        }).then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error("Network response was not ok.");
          })
          .then((res) => {
            setEvents(res)
          })
          .catch(() => {
            navigate("/")
          });
    }, []);

    const renderState = (state) => {
      if (state == "pending") {
        return <div className="state pending">En attente</div>
      } else if (state == "booked") {
        return <div className="state booked">Plannifié</div>
      } else if (state == "done") {
        return <div className="state done">Réalisé</div>
      } else if (state == "canceled") {
        return <div className="state canceled">Refusé</div>
      }
    }

    const columns = [
      {Header: "", accessor: "id",  disableFilters: true, show: false},
      {
        Header: "Date", 
        accessor: "event_date",  
        disableFilters: true, 
        show: true,
        Cell: ({ row }) => <div onClick={() => navigate(`/events/${row.values.id}`)}>{row.values.event_date}</div>
      },
      {
        Header: "Dénomination du Client", 
        accessor: "client_name",  
        disableFilters: true, 
        show: true,
        Cell: ({ row }) => <div onClick={() => navigate(`/events/${row.values.id}`)}>{row.values.client_name}</div>
      },
      {
        Header: "Nom de l’évènement", 
        accessor: "name",  
        disableFilters: true, 
        show: true,
        Cell: ({ row }) => <div onClick={() => navigate(`/events/${row.values.id}`)}>{row.values.name}</div>
      },
      {
        Header: "Ville de réalisation", 
        accessor: "city",  
        disableFilters: true, 
        show: true,
        Cell: ({ row }) => <div onClick={() => navigate(`/events/${row.values.id}`)}>{row.values.city}</div>
      },
      {
        Header: "Adresse de réalisation", 
        accessor: "localisation",  
        disableFilters: true, 
        show: true,
        Cell: ({ row }) => <div onClick={() => navigate(`/events/${row.values.id}`)}>{row.values.localisation}</div>
      },
      { 
        Header: "Statut", 
        accessor: "state",  
        disableFilters: true, 
        show: true,
        Cell: ({ row }) => {
          return (
            <div onClick={() => navigate(`/events/${row.values.id}`)}>
              {renderState(row.values.state)}
            </div>
          )
        }
      },  
    ]

    const listEvents = <DataTable columns={columns} data={events} search={false} s />

    const noEvent = (
      <div className="nocontent">
        <h4>
          Pas d'Évènements remontés
        </h4>
      </div>
    );
   
    return (
        <div className="main">
            <Navbar />
            <div className="events work-space">
                <div className="header">
                </div>
                <div className="body">
                    <h2>Évènements</h2>
                    {events.length > 0 ? listEvents : noEvent}
                </div>
            </div>
        </div>
    )

};

export default Events;
