import React, { useEffect } from "react";

const TextInput = ({editable, object, setObject, slug, title, setRefresh}) => {
    
    const handleChange = () => {
        setObject( { ...object, [slug]: event.target.value })
    }

    const handleBlur = () => {
        setRefresh(true)
        setRefresh(`${slug}-${event.target?.value}`)
    }
    
    if (editable) {
    return (  
        <div className="form-bloc">
            <label htmlFor={slug} id={slug} className="s_label">{title}</label>
            <input
              type="text"
              className="list-input"
              autoComplete="off"
              value={object[slug]}
              onChange={handleChange}
              onBlur={handleBlur}
           />
        </div>
    ) } else {
        return (  
            <div className="form-bloc">
                <label htmlFor={slug} id={slug} className="s_label">{title}</label>
                <p>{object[slug]}</p>
            </div>
        ) 
    }

};

export default TextInput;
