import React, { useEffect } from "react";
import Form from 'react-bootstrap/Form';

const BooleanInput = ({editable, object, setObject, slug, title, setRefresh}) => {
    
    const handleChange = ({ target: { checked } }) => {
        setObject( { ...object, [slug]: checked }  )
        setRefresh(`${slug}-${checked}`)
    }
    
    
    if (editable) {
    return (  
        <div className="form-bloc">
            <Form>
                <Form.Check 
                    type="switch"
                    label={title}
                    checked={object[slug]}
                    onChange={handleChange}
                />
            </Form>
        </div>
    ) } else {
        return (  
            <div className="form-bloc">
                <Form>
                    <Form.Check 
                        type="switch"
                        label={title}
                        checked={object[slug]}
                        onChange={handleChange}
                        disabled
                    />
                </Form>
            </div>
        ) 
    }

};

export default BooleanInput;
