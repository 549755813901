import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from './navbar'
import Form from './form/profile_form'

const Profile = () => {

  const navigate = useNavigate();
  const [technician, setTechnician] = useState({});
  const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        const url = `/api/v1/technicians`;
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content
        fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        }).then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error("Network response was not ok.");
          })
          .then((res) => {
            setTechnician(res)
          })
          .catch(() => {
            navigate("/")
          });
    }, []);

    useEffect(() => {
      if (technician.id) {
        const url = `/api/v1/technicians/${technician.id}`;
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content
        const body = {technician}
        fetch(url, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          body: JSON.stringify(body),
          credentials: "same-origin",
        }).then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error("Network response was not ok.");
          })
          .then((res) => {
            setTechnician(res)
          })
          .catch(() => {
            navigate("/")
          });
      }
  }, [refresh]);
   
    return (
        <div className="main">
            <Navbar user={technician} />
            <div className="profile work-space">
                <div className="header">
                </div>
                <div className="body">
                    <Form technician={technician} setTechnician={setTechnician} setRefresh={setRefresh} />
                </div>
            </div>
        </div>
    )

};

export default Profile;
