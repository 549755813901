import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const PendingEvent = ({user, manageEvent}) => {
    const navigate = useNavigate();

    const manage = (event, state) => {
        manageEvent(event, state)
    }

    const renderActions = (event) => {
        return (
                <div className="actions">
                    <FontAwesomeIcon onClick={() => manage(event, "accept")} icon={faCheckCircle} color="#70b996"/>
                    <FontAwesomeIcon onClick={() => manage(event, "refuse")} icon={faTimesCircle} color="#c25343"/>
                </div>
            )
    }
   
    return (
        <div className={`${user?.new_assignations?.length > 0 ? 'show' : ''} pending-events`}>
            <div className="week-header">
                <h3><FontAwesomeIcon icon={faExclamationCircle} color="#c25343"/>{user?.new_assignations?.length} Nouveaux évènements proposés</h3>
            </div>

            <div className="event-list">
                {
                    user?.new_assignations?.map((e, idx) => {
                        return (
                            <div key={idx} className={`${e.state} card event-card`}>
                                <div className="header">
                                    <div className="localisation">{e.city} {e.localisation}</div>
                                    <div className="date">{e.event_date}</div>
                                </div>
                                <div className="name">{e.name}</div>
                                {renderActions(e)}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )

};

export default PendingEvent;
